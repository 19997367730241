import React, {forwardRef, useRef} from "react";
import StarterKit from "@tiptap/starter-kit";
import {
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonItalic,
    MenuButtonStrikethrough,
    MenuButtonUnderline,
    MenuControlsContainer,
    RichTextEditor,
    type RichTextEditorRef,
    MenuDivider,
    MenuButtonRemoveFormatting,
    MenuSelectTextAlign,
    MenuButtonEditLink,
    LinkBubbleMenuHandler, LinkBubbleMenu,
} from "mui-tiptap";
import {Underline} from "@tiptap/extension-underline";
import {Color} from "@tiptap/extension-color";
import {TextStyle} from "@tiptap/extension-text-style";
import {TextAlign} from "@tiptap/extension-text-align";
import {Link} from "@tiptap/extension-link";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string;
    defaultValue: string | null;
}

export const SimpleHTMLInput = forwardRef<HTMLInputElement, CustomProps>(
    function SimpleHTMLInput(props, ref) {
        const {onChange, defaultValue, ...other} = props;
        const rteRef = useRef<RichTextEditorRef>(null);

        return (
            <RichTextEditor
                {...other}
                ref={rteRef}
                extensions={[StarterKit, Underline, Color, TextStyle]}
                content={defaultValue}
                RichTextFieldProps={{variant: "outlined"}}
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuButtonBold/>
                        <MenuButtonItalic/>
                        <MenuButtonUnderline/>
                        <MenuButtonStrikethrough/>
                    </MenuControlsContainer>
                )}
                onUpdate={e => onChange({target: {name: props.name, value: e.editor.getHTML()}})}
            />);
    },
);

export const HTMLInput = forwardRef<HTMLInputElement, CustomProps>(
    function HTMLInput(props, ref) {
        const {onChange, defaultValue, ...other} = props;
        const rteRef = useRef<RichTextEditorRef>(null);

        return (
            <RichTextEditor
                {...other}
                ref={rteRef}
                extensions={[StarterKit, Underline, Color, TextStyle, TextAlign, LinkBubbleMenuHandler, Link]}//Highlight.configure({ multicolor: true }),
                content={defaultValue}
                RichTextFieldProps={{variant: "outlined"}}
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuButtonBold/>
                        <MenuButtonItalic/>
                        <MenuButtonUnderline/>
                        <MenuButtonStrikethrough/>
                        <MenuButtonBulletedList/>
                        <MenuDivider/>
                        <MenuSelectTextAlign/>
                        <MenuDivider/>
                        <MenuButtonEditLink/>
                        <MenuDivider/>
                        <MenuButtonRemoveFormatting/>
                    </MenuControlsContainer>
                )}
                onUpdate={e => onChange({target: {name: props.name, value: e.editor.getHTML()}})}
            >
                {() => (
                    <LinkBubbleMenu
                        labels={{
                            viewLinkEditButtonLabel: "Изменить ссылку",
                            viewLinkRemoveButtonLabel: "Удалить ссылку",
                            editLinkAddTitle: "Добавить ссылку",
                            editLinkEditTitle: "Обновить ссылку",
                            editLinkCancelButtonLabel: "Отмена",
                            editLinkTextInputLabel: "Текст",
                            editLinkHrefInputLabel: "URL",
                            editLinkSaveButtonLabel: "Сохранить",
                        }}
                    />
                )}
            </RichTextEditor>);
    },
);