import {IMaskInput} from "react-imask";
import {forwardRef} from "react";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    onInput: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const PhoneInput = forwardRef<HTMLInputElement, CustomProps>(
    function PhoneInput(props, ref) {
        const { onChange, onInput, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={'(#00) 000-0000'}
                definitions={{'#': /[1-9]/}}
                inputRef={ref}
                onInput={e => onChange({ target: { name: props.name, value: e.currentTarget.value } })}
                onChange={e => onChange({ target: { name: props.name, value: e.currentTarget.value } })}
                overwrite
            />
        );
    },
);