import {ChangeEvent, FormEvent, ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from "@mui/material";
import {REST} from "../REST";
import {ThemeType} from "../type/ThemeType";

export default function ThemeModal(props: any): ReactElement {
    const [theme, setTheme] = props.theme;
    const [me, setMe] = props.me;

    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [currentName, setCurrentName] = useState<string>('');

    const handleOnChangeText = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentName(event.target.value);
        if (event.target.value.length > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //const formData = new FormData(event.currentTarget);
        //const formJson = Object.fromEntries((formData as any).entries());

        //const name = formJson.name;
        const newTheme: ThemeType = {
            id: BigInt(0),
            name: currentName!,
            author: me,
            messageCount: BigInt(0),
            status: 0,
            lastMessage: null
        }
        REST.saveTheme(newTheme).then(t => {
            setTheme(null);
        });
    };

    return (<Dialog
        fullWidth
        open={theme !== null}
        onClose={() => setTheme(null)}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit
        }}
    >
        <DialogContent>

            <TextField sx={{mt: 2}}
                       id={"name"}
                       label={"Название темы"}
                       name={"name"}
                       fullWidth
                       required
                       onChange={handleOnChangeText}
            />

        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setTheme(null)}>Отмена</Button>
            <Button color={'success'} type="submit" disabled={saveDisabled} variant={"contained"}>Создать</Button>
        </DialogActions>
    </Dialog>);
}