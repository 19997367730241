import React, {ReactElement} from "react";
import {Navigate} from "react-router-dom";
import {MenuBlock} from "../component/MenuBlock";
import {
    Avatar, Card,
    CardActions,
    CardContent,
    Container,
    Divider,
    Grid2,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {REST} from "../REST";
import {Normalizer} from "../utils/Normalizer";

export default function MePage(props: any): ReactElement {
    const [me, setMe] = props.me;

    if(me.id === undefined) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 spacing={0} sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>


                <Card sx={{mt: 1, mb: 2}} variant="outlined">
                    <CardContent>
                        <Typography variant="h6" align={'center'} gutterBottom>itemPart.title</Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}} align={'center'} >itemPart.content</Typography>
                    </CardContent>
                    <Divider/>
                    <CardActions disableSpacing>
                        <Stack direction="row" sx={{ml: 'auto'}} spacing={1}>
                            <Avatar src={REST.BASE + '/api/user/' + me.id + '/avatar'}/>
                            <Stack>
                                <Typography variant={'body2'}>{Normalizer.shortFio(me.fio)}</Typography>
                                <Typography variant={'caption'}>itemPart.publishDate</Typography>
                            </Stack>
                        </Stack>
                    </CardActions>
                </Card>


            </Container>
        </Grid2>
    );
}