import {UserType} from "../type/UserType";

export class Normalizer {
    public static phone(tel: string): string {
        return tel.replaceAll(/[()\s-]/g, "");
    }

    public static formatPhone(tel: string): string {
        const mask: string = '(000)000-00-00';
        var ret = '';
        for (var _im: number = 0, _is: number = 0; _im < mask.length && _is < (tel.length); _im++) {
            ret += mask.charAt(_im) === '0' ? tel.charAt(_is++) : mask.charAt(_im);
        }
        return ret;
    }

    public static shortFio(fio: string): string {
        let ff: string[] = fio.split(' ');
        return ff[0] + (ff.length > 1 ? (" " + ff.slice(1).map(f => f.substring(0, 1)).join(".") + ".") : "");
    }

    public static hasRole(role: string): boolean {
        const me: UserType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
        for (let i = 0; i < (me.roles?.length ?? 0); i++) {
            if (me.roles![i]?.code === role) {
                return true;
            }
        }
        return false;
    }

    public static toRoman(n: number): string {
        switch (n) {
            case 1:
                return 'I';
            case 2:
                return 'II';
            case 3:
                return 'III';
            case 4:
                return 'IV';
            case 5:
                return 'V';
            case 6:
                return 'VI';
            case 7:
                return 'VII';
            case 8:
                return 'VIII';
            case 9:
                return 'IX';
            case 10:
                return 'X';
            default:
                return n.toString();
        }
    }
}
