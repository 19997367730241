import {ChangeEvent, FormEvent, ReactElement, useEffect, useRef, useState} from "react";
import {REST} from "../REST";
import {
    Autocomplete,
    Button, createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from "@mui/material";
import {SectionType} from '../type/DocumentType';
import {VisuallyHiddenInput} from "../component/VisuallyHiddenInput";
import FileUploadIcon from '@mui/icons-material/FileUpload';

const filter = createFilterOptions<SectionType>();

export default function DocumentModal(props: any): ReactElement {
    const [document, setDocument] = props.doc;
    //const [me, setMe] = props.me;
    const [sections, setSections] = useState<SectionType[]>([]);

    const nameInput = useRef<HTMLInputElement>(null);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [currentBlob, setCurrentBlob] = useState<File | null>(null);
    const [currentName, setCurrentName] = useState<string>('');
    const [section, setSection] = useState<SectionType | null>(null);

    useEffect(() => {
        setCurrentName(document?.name ?? '');
        REST.getDocumentSections().then(ss => {
            setSections(ss);
        });
        setSection(document?.section ?? null);
    }, [document]);

    const handleOnChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentName(event.target.value);
        if (event.target.value.length > 0 && (section?.name.length ?? 0) > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", currentName);
        formData.append("sid", section!.id.toString());
        formData.append("sectionName", section!.name);
        formData.append("blob", currentBlob as Blob);

        REST.uploadDocument(formData).then(d => {
            setDocument(null);
        });
    };

    const remove = () => {
        REST.removeDocument(document.id).then(() => {
            setDocument(null);
        });
    }

    return (<Dialog
        fullWidth
        open={document !== null}
        onClose={() => setDocument(null)}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit
        }}
    >
        <DialogContent>

            <Button sx={{mt: 1}}
                    component="label"
                    variant="contained"
                    tabIndex={-1}
                    fullWidth
                    disabled={document?.id !== BigInt(0)}
                    startIcon={<FileUploadIcon/>}
            >
                Загрузить документ
                <VisuallyHiddenInput
                    type="file"
                    name={"blob"}
                    required
                    onChange={(event) => {
                        setCurrentBlob(event.target.files![0]);
                        setCurrentName(event.target.files![0].name ?? '');
                    }}
                />
            </Button>

            <TextField sx={{mt: 2}}
                       id={"name"}
                       label={"Название"}
                       name={"name"}
                       type={"text"}
                       required
                       fullWidth
                       onChange={handleOnChangeName}
                       value={currentName}
                       inputRef={nameInput}
                       disabled={document?.id !== BigInt(0)}
            />

            <Autocomplete
                value={section}
                disabled={document?.id !== BigInt(0)}
                onChange={(event, newValue) => {
                    if (newValue === null) {
                        setSection(null);
                        setSaveDisabled(true);
                    } else if (typeof newValue === 'string') {
                        console.warn(newValue);
                    } else {
                        setSection({id: newValue.id, name: newValue.inputValue ?? newValue.name, inputValue: null});
                        if (currentName.length > 0 && ((newValue.inputValue?.length ?? 0) > 0 || (newValue.name?.length ?? 0) > 0)) {
                            setSaveDisabled(false);
                        }
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const {inputValue} = params;
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({id: BigInt(0), name: `Добавить "${inputValue}"`, inputValue: inputValue})
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="section"
                options={sections}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.inputValue ?? option.name;
                }}
                renderOption={(props, option) => {
                    const {key, ...optionProps} = props;
                    return (<li key={key} {...optionProps}>{option.name}</li>);
                }}
                sx={{mt: 2}}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} required label="Раздел" name={'section'}/>
                )}
            />

        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setDocument(null)}>Отмена</Button>
            {document?.id === BigInt(0)
                ? <Button color={'success'} type="submit" disabled={saveDisabled} variant={"contained"}>Сохранить</Button>
                : <Button color={"error"} variant={"contained"} onClick={() => remove()}>Удалить</Button>
            }
        </DialogActions>
    </Dialog>);
}