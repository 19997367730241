import {ChangeEvent, FormEvent, ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from "@mui/material";
import {REST} from "../REST";
import {SimpleHTMLInput} from "../component/HTMLInput";
import {BannerType} from "../type/BannerType";

export default function BannerModal(props: any): ReactElement {
    const [banner, setBanner] = props.banner;
    //const [me, setMe] = props.me;

    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [currentText, setCurrentText] = useState<string>('');

    const handleOnChangeText = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentText(event.target.value);
        if (event.target.value.length > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());

        const title = formJson.title;
        const newBanner: BannerType = {
            id: banner.id,
            title: title.length > 0 ? title : null,
            text: currentText!
        }
        REST.saveBanner(newBanner).then(b => {
            setBanner(null);
        });
    };

    const removeBanner = () => {
        REST.removeBanner(banner.id).then(() => {
            setBanner(null);
        });
    }

    return (<Dialog
        fullWidth
        open={banner !== null}
        onClose={() => setBanner(null)}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit
        }}
    >
        <DialogContent>
            {(banner?.id ?? 0) > 0
                ? <img src={REST.BASE + "/api/banner/" + (banner?.id ?? 0) + "/img"} alt={""}/>
                : ''
            }

            <TextField sx={{mt: 1}}
                       id={"title"}
                       label={"Заголовок"}
                       name={"title"}
                       type={"text"}
                       fullWidth
                       slotProps={{inputLabel: {shrink: true}}}
                       defaultValue={banner?.title}
            />

            <TextField sx={{mt: 2}}
                       id={"text"}
                       label={"Текст"}
                       name={"text"}
                       fullWidth
                       required
                       multiline
                       slotProps={{
                           input: {
                               inputComponent: SimpleHTMLInput as any
                           },
                           inputLabel: {shrink: true},
                       }}
                       onChange={handleOnChangeText}
                       defaultValue={banner?.text}
            />

        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setBanner(null)}>Отмена</Button>
            {banner?.id === BigInt(0)
                ? ''
                : <Button color={"error"} variant={"contained"} onClick={() => removeBanner()}>Удалить</Button>
            }
            <Button color={'success'} type="submit" disabled={saveDisabled} variant={"contained"}>Сохранить</Button>
        </DialogActions>
    </Dialog>);
}