import React, {ReactElement, useEffect, useState} from "react";
import {
    Box,
    CircularProgress,
    Container,
    Grid2,
    List,
    ListItemButton, ListItemText,
    ListSubheader,
    Toolbar,
    Typography
} from "@mui/material";
import {MenuBlock} from "../component/MenuBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import {DocumentType, SectionType} from "../type/DocumentType";
import {REST} from "../REST";
import {DocumentItem} from "../component/DocumentItem";

export default function DocumentPage(props: any): ReactElement {
    const [me, setMe] = props.me;

    const pageSize: number = 10;
    const [items, setItems] = useState<DocumentType[]>([]);
    const [sections, setSections] = useState<SectionType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [filter, setFilter] = useState<SectionType | null>(null);

    useEffect(() => {
        REST.getDocumentSections().then(ss => {
            setSections(ss);
        });
        if (currentPage === 0) {
            REST.getDocuments(currentPage, pageSize).then(dd => {
                if (dd.length < pageSize) {
                    setHasMore(false);
                }
                setItems(dd);
                //setSections(Array.from(new Set(dd.map(d => d.section).sort((s1, s2) => s1.name.localeCompare(s2.name)))));
            });
        }
    }, [currentPage]);

    const fetchMore = () => {
        REST.getDocuments(currentPage, pageSize).then(dd => {
            setCurrentPage(prevPage => prevPage + 1);
            setItems(prevItems => [...prevItems, ...dd]);
        });
    };

    const downloadFile = (item: DocumentType) => {
        REST.downloadDocument(item);
    };

    return (<>
        <Grid2 sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <Grid2 container spacing={1} columns={{xs: 1, sm: 4}}>

                    <Grid2 columns={1} size={1} sx={{mt: 1}}>
                        <List sx={{bgcolor: 'background.paper'}}
                              subheader={<ListSubheader component="div">Разделы</ListSubheader>}>
                            <ListItemButton alignItems="flex-start" key={0} selected={filter === null}
                                            onClick={() => setFilter(null)}>
                                <ListItemText primary={<b>Все документы</b>}/>
                            </ListItemButton>
                            {sections?.map(s =>
                                <ListItemButton alignItems="flex-start" key={s.id} selected={filter?.id === s.id}
                                                onClick={() => setFilter(s)}>
                                    <ListItemText primary={s.name}/>
                                </ListItemButton>
                            )}
                        </List>
                    </Grid2>

                    <Grid2 sx={{mt: {xs: 0, sm: 1}}} size={{xs: 1, sm: 3}}>
                        <List sx={{bgcolor: 'background.paper'}}
                              subheader={<ListSubheader component="div">Документы</ListSubheader>}>
                            <InfiniteScroll
                                dataLength={items.length}
                                next={fetchMore}
                                hasMore={hasMore}
                                loader={<Box
                                    sx={{
                                        width: '100%',
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                        mt: 1
                                    }}><CircularProgress
                                    color="inherit"/></Box>}
                                endMessage={items.length > 0 ? '' :
                                    <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}>
                                        <Typography variant={'caption'}><i>нет документов</i></Typography></Box>}
                            >
                                {items
                                    .filter((d) => {
                                        if (filter !== null) return d.section.id === filter.id;
                                        else return true;
                                    })
                                    .map((item, index) => (
                                        <DocumentItem key={item.id} item={item} onClick={() => downloadFile(item)}/>
                                    ))}
                            </InfiniteScroll>
                        </List>
                    </Grid2>

                </Grid2>
            </Container>

        </Grid2>
    </>)
}
