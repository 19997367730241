import React, {ReactElement, useEffect, useState} from "react";
import {DocumentType} from "../../type/DocumentType";
import {Normalizer} from "../../utils/Normalizer";
import {Navigate} from "react-router-dom";
import {REST} from "../../REST";
import {Container, Grid2, List, ListItemButton, ListItemText, Toolbar} from "@mui/material";
import {AdminMenuBlock} from "../../component/admin/AdminMenuBlock";
import AddIcon from "@mui/icons-material/Add";
import {SimpleDocumentItem} from "../../component/DocumentItem";
import DocumentModal from "../../modal/DocumentModal";

export default function AdminDocumentPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const [items, setItems] = useState<DocumentType[]>([]);
    const [current, setCurrent] = useState<DocumentType | null>(null);
    const empty: DocumentType = {id: BigInt(0), name: '', description: null, uploadedAt: '', uploadedBy: me, section: {id: BigInt(0), name: '', inputValue: null}};

    useEffect(() => {
        REST.getDocuments(0, 10).then(dd => {
            setItems(dd);
        }).catch(e => {

        });
    }, [current])

    if(me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <AdminMenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <List sx={{bgcolor: 'background.paper', mt: 2}}>
                    <ListItemButton onClick={() => setCurrent(empty)}>
                        <ListItemText sx={{textAlign: 'center'}} primary={<AddIcon/>}/>
                    </ListItemButton>
                    {items.map((item, index) => <SimpleDocumentItem key={item.id} item={item}
                                                                    onClick={() => setCurrent(item)}/>)}
                </List>
            </Container>

            <DocumentModal doc={[current, setCurrent]} me={[me, setMe]}/>
        </Grid2>
    );
}