import React, {ReactElement, useEffect, useState} from "react";
import {Box, CircularProgress, Container, Grid2, Toolbar, Typography} from "@mui/material";
import {NewsItem} from "../component/NewsItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import {NewsType} from "../type/NewsType";
import {REST} from "../REST";
import {MenuBlock} from "../component/MenuBlock";

export default function NewsPage(props: any): ReactElement {
    const [me, setMe] = props.me;

    const pageSize: number = 10;
    const [items, setItems] = useState<NewsType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        if (currentPage === 0) {
            REST.getNews(currentPage, pageSize).then(nn => {
                if (nn.length < pageSize) {
                    setHasMore(false);
                }
                setItems(nn);
            });
        }
    }, [currentPage]);

    const fetchMore = () => {
        REST.getNews(currentPage, pageSize).then(nn => {
            setCurrentPage(prevPage => prevPage + 1);
            setItems(prevItems => [...prevItems, ...nn]);
        });
    };

    return (<>
        <Grid2 sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={<Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}><CircularProgress
                        color="inherit"/></Box>}
                    endMessage={items.length > 0 ? '' :
                        <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}>
                            <Typography>Новостей и объявлений нет</Typography></Box>}
                >
                    {items.map((item, index) => (
                        <NewsItem key={item.id} news={item}/>
                    ))}
                </InfiniteScroll>
            </Container>

        </Grid2>
    </>)
}