import {jwtDecode} from 'jwt-decode';
import {saveAs} from "file-saver";
import {NewsType} from "./type/NewsType";
import {DocumentType, SectionType} from "./type/DocumentType";
import {JWT} from "./type/JWT";
import {BannerType} from "./type/BannerType";
import {UserType} from "./type/UserType";
import {RoleType} from "./type/RoleType";
import {RequestType, RequestTypeType} from "./type/RequestType";
import {PlotType} from "./type/PlotType";
import {ThemeType} from "./type/ThemeType";
import {MessageType} from "./type/MessageType";

export class REST {
    public static BASE: String = process.env.REACT_APP_BASE ?? "";

    public static login(username: string, password: string): Promise<JWT> {
        return fetch(this.BASE + "/api/auth", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username: username, password: password})
        })
            .then((response) => {
                if (response.status === 401) {
                    return response.json()
                        .then((e) => {
                            throw new Error(e['message'])
                        })
                        .catch((e: Error) => {
                            throw new Error(e.message)
                        });
                }
                return response.json();
            })
            .then((token) => {
                const jwt = jwtDecode<JWT>(token?.access_token);
                sessionStorage.setItem("me", JSON.stringify(jwt.user));
                sessionStorage.setItem("jwt", token?.access_token);
                //localStorage.setItem('token', token);
                return jwt;
            });
    }

    public static logout(): void {
        fetch(this.BASE + "/api/logout", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                sessionStorage.removeItem("me");
                sessionStorage.removeItem("jwt");
                //window.location.href = '/';
            });
    }

    public static GET<T>(uri: string): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    throw new Error();
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static POST<T>(uri: string, body: T): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    throw new Error();
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static PUT<T>(uri: string, body: T): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    throw new Error();
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static DELETE(uri: string): Promise<any> {
        return fetch(this.BASE + uri, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/login';
                }
                return response;
            });
    }

    public static getNews(page: number, pageSize: number): Promise<NewsType[]> {
        return REST.GET("/api/news?page=" + page + "&size=" + pageSize);
    }

    public static getDocuments(page: number, pageSize: number): Promise<DocumentType[]> {
        return REST.GET("/api/doc?page=" + page + "&size=" + pageSize);
    }

    public static getRequests(page: number, pageSize: number): Promise<RequestType[]> {
        return REST.GET("/api/request?page=" + page + "&size=" + pageSize);
    }

    public static getDocumentSections(): Promise<SectionType[]> {
        return REST.GET("/api/doc/sections");
    }

    public static getBanners(): Promise<BannerType[]> {
        return REST.GET("/api/banner");
    }

    public static getUsers(): Promise<UserType[]> {
        return REST.GET("/api/user");
    }

    public static getRoles(): Promise<RoleType[]> {
        return REST.GET("/api/user/roles");
    }

    public static getRequestTypes(): Promise<RequestTypeType[]> {
        return REST.GET("/api/request/type");
    }

    public static like(id: bigint): Promise<NewsType> {
        return REST.GET<NewsType>("/api/news/" + id + "/like");
    }

    public static saveBanner(banner: BannerType): Promise<BannerType> {
        return REST.PUT<BannerType>("/api/banner", banner);
    }

    public static removeBanner(id: bigint): Promise<any> {
        return REST.DELETE("/api/banner?id=" + id);
    }

    public static blockUser(id: bigint): Promise<any> {
        return REST.DELETE("/api/user?id=" + id);
    }

    public static saveNews(news: NewsType): Promise<NewsType> {
        return REST.PUT<NewsType>("/api/news", news);
    }

    public static removeNews(id: bigint): Promise<any> {
        return REST.DELETE("/api/news?id=" + id);
    }

    public static removeDocument(id: bigint): Promise<any> {
        return REST.DELETE("/api/doc?id=" + id);
    }

    public static uploadDocument(form: FormData): Promise<DocumentType> {
        return fetch(this.BASE + "/api/doc", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: form
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    throw new Error();
                }
                return response.json();
            })
            .then((data: Promise<DocumentType>) => {
                return data;
            });
    }

    public static downloadDocument(doc: DocumentType): void { //FIXME: mobile download not work
        fetch(this.BASE + "/api/doc/download?id=" + doc.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, doc.name);
            });
    }

    public static createRequest(request: RequestType): Promise<RequestType> {
        return REST.POST<RequestType>("/api/request", request);
    }

    public static getPlotSectors(): Promise<number[]> {
        return REST.GET<number[]>("/api/plot/sectors");
    }

    public static getPlotBySector(sector: number): Promise<PlotType[]> {
        return REST.GET<PlotType[]>("/api/plot?sector=" + sector);
    }

    public static getForumThemes(): Promise<ThemeType[]> {
        return REST.GET<ThemeType[]>("/api/forum/theme");
    }

    public static getForumMessages(theme: bigint, page: number, pageSize: number): Promise<MessageType[]> {
        return REST.GET<MessageType[]>("/api/forum/" + theme.toString() + "/?page=" + page + "&size=" + pageSize);
    }

    public static saveTheme(theme: ThemeType): Promise<ThemeType> {
        return REST.POST<ThemeType>("/api/forum/theme", theme);
    }

    public static sendMessage(msg: MessageType): Promise<MessageType> {
        return REST.POST<MessageType>("/api/forum", msg);
    }

    public static closeTheme(tid: bigint): Promise<any> {
        return REST.DELETE("/api/forum/theme?id=" + tid.toString());
    }

    public static acceptRequest(rid: bigint): Promise<any> {
        return REST.GET("/api/request/" + rid.toString() + "/accept");
    }

    public static answerRequest(request: RequestType): Promise<RequestType> {
        return REST.PUT("/api/request", request);
    }
}