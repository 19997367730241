import React, {ReactElement, useState} from "react";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton, Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import LoginModal from "../modal/LoginModal";
import ProfileMenu from "./ProfileMenu";
import {REST} from "../REST";
import {useNavigate} from "react-router-dom";

export function MenuBlock(props: any): ReactElement {
    const [me, setMe] = props.me;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (<>
        <AppBar>
            <Container>
                <Toolbar>
                    <Box>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2, display: {md: 'none'}}}
                            onClick={handleClick}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="menu"
                            open={anchorEl !== null}
                            onClose={handleClose}
                            onClick={handleClose}
                        >
                            <MenuItem key={'index'} onClick={() => navigate('/')}>Главная</MenuItem>
                            <MenuItem key={'news'} onClick={() => navigate('/news')}>Новости</MenuItem>
                            <MenuItem key={'docs'} onClick={() => navigate('/docs')}>Документы</MenuItem>
                            <MenuItem key={'requests'} onClick={() => navigate('/requests')}>Обращения</MenuItem>
                            <MenuItem key={'forum'} onClick={() => navigate('/forum')}>Форум</MenuItem>
                        </Menu>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button key={'index'} sx={{my: 2, color: 'white', display: 'block', textTransform: 'none'}}
                                href={'/'}>
                            <Typography variant="subtitle1">{process.env.REACT_APP_TITLE}</Typography>
                        </Button>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button key={'news'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/news'}>Новости</Button>
                        <Button key={'docs'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/docs'}>Документы</Button>
                        <Button key={'requests'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/requests'}>Обращения</Button>
                        <Button key={'forum'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/forum'}>Форум</Button>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {md: 'none'}}}/>
                    <Box>
                        {me.id === undefined
                            ? <Button color={"inherit"} onClick={() => setOpen(true)}><LoginIcon
                                sx={{mr: 1}}/>Войти</Button>
                            : <>
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    size="small"
                                    sx={{ml: 2}}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar src={REST.BASE + '/api/user/' + me.id + '/avatar'}/>
                                </IconButton>
                                <ProfileMenu anchor={[anchorElUser, setAnchorElUser]} me={[me, setMe]}/>
                            </>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

        <LoginModal openState={[open, setOpen]} me={[me, setMe]}/>
    </>);
}