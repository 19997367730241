import React, {ReactElement} from "react";
import {
    Stack,
    Card,
    CardContent,
    Typography,
    CardActions,
    Chip, Divider, CardActionArea
} from '@mui/material';
import {RequestType} from "../type/RequestType";
import {Normalizer} from "../utils/Normalizer";

export function RequestItem(props: any): ReactElement {
    const itemPart: RequestType = props.item as RequestType;
    const content = JSON.parse(itemPart.content);
    const answer = JSON.parse(itemPart.answer);

    return (
        <Card sx={{mt: 1, mb: 1}} variant="outlined">
            <CardContent>
                <Typography variant="body2" align={'center'}
                            gutterBottom>{itemPart.type.name} от {itemPart.publishDate}</Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}}
                            align={'left'}>{(itemPart.type.code === 'appeal_public' || itemPart.type.code === 'appeal_private' || itemPart.type.code === 'statement')
                    ? content.content
                    : ("Место проведения работ: " + Normalizer.toRoman(content.plot.sector) + " квартал, участок №" + content.plot.number)
                }</Typography>
                {itemPart.completedDate !== undefined
                    ? <>
                        <Divider sx={{mt: 1, mb: 1}}/>
                        <Typography variant="body2" color={itemPart.expired ? "error" : "success"}>{"Ответ от " + itemPart.completedDate + ":"}</Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}} align={'left'}
                                    dangerouslySetInnerHTML={{
                                        __html: answer.answer !== undefined
                                            ? answer.answer
                                            : "Исполнителем в лице <b>" + answer.executor + "</b> выполнены работы на протяжении <b>"
                                            + answer.length + "</b> метров за <b>" + answer.price +
                                            "</b> руб. с использованием <b>" + answer.material + "</b>"
                                    }}></Typography>
                    </>
                    : ""
                }
            </CardContent>
            {/*itemPart.acceptedDate !== undefined || itemPart.completedDate !== undefined
                ? <>
                    <Divider/>
                    <CardActions disableSpacing>
                        <Stack direction="row" sx={{ml: 'auto'}} spacing={1}>
                            {itemPart.acceptedDate !== undefined
                                ? <Chip label={"Принято в работу " + itemPart.acceptedDate} color="warning"/>
                                : ""
                            }
                            {itemPart.completedDate !== undefined
                                ? <Chip label={"Дан ответ " + itemPart.completedDate}
                                        color={itemPart.expired ? "error" : "success"}/>
                                : ""
                            }
                        </Stack>
                    </CardActions>
                </>
                : ""*/
            }
        </Card>
    );
}

export function ExtendedRequestItem(props: any): ReactElement {
    const itemPart: RequestType = props.item as RequestType;
    const content = JSON.parse(itemPart.content);
    const onClick = props.onClick;

    return (
        <Card sx={{mt: 1, mb: 1}} variant="outlined">
            <CardActionArea onClick={onClick} disabled={itemPart.completedDate !== undefined}>
                <CardContent>
                    <Typography variant="body2" align={'center'}
                                gutterBottom>{itemPart.type.name + " " + itemPart.publishDate + " от " + Normalizer.shortFio(itemPart.author.fio)}</Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}
                                align={'left'}>{(itemPart.type.code === 'appeal_public' || itemPart.type.code === 'appeal_private' || itemPart.type.code === 'statement')
                        ? content.content
                        : ("Место проведения работ: " + Normalizer.toRoman(content.plot.sector) + " квартал, участок №" + content.plot.number)
                    }</Typography>
                </CardContent>
                <Divider/>
                <CardActions disableSpacing>
                    <Stack direction="row" sx={{ml: 'auto'}} spacing={1}>
                        {itemPart.acceptedDate === undefined && itemPart.completedDate === undefined
                            ? <Chip label={"Новое обращение"} color="primary"/>
                            : ""
                        }
                        {itemPart.acceptedDate !== undefined
                            ? <Chip label={"Принято в работу " + itemPart.acceptedDate} color="warning"/>
                            : ""
                        }
                        {itemPart.completedDate !== undefined
                            ? <Chip label={"Дан ответ " + itemPart.completedDate}
                                    color={itemPart.expired ? "error" : "success"}/>
                            : ""
                        }
                    </Stack>
                </CardActions>
            </CardActionArea>
        </Card>
    );
}