import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {Normalizer} from "../../utils/Normalizer";
import {Navigate} from "react-router-dom";
import {
    Box,
    CircularProgress,
    Container,
    Grid2,
    Toolbar,
    Typography
} from "@mui/material";
import {AdminMenuBlock} from "../../component/admin/AdminMenuBlock";
import {RequestType} from "../../type/RequestType";
import {ExtendedRequestItem} from "../../component/RequestItem";
import InfiniteScroll from "react-infinite-scroll-component";
import AdminRequestModal from "../../modal/AdminRequestModal";

export default function AdminRequestPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const pageSize: number = 10;
    const [items, setItems] = useState<RequestType[]>([]);
    const [current, setCurrent] = useState<RequestType | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    //const empty: RequestType = {id: BigInt(0), name: '', description: null, uploadedAt: '', uploadedBy: me, section: {id: BigInt(0), name: '', inputValue: null}};

    useEffect(() => {
        if (currentPage === 0) {
            REST.getRequests(currentPage, pageSize).then(rr => {
                if (rr.length < pageSize) {
                    setHasMore(false);
                }
                setItems(rr);
            });
        }
    }, [currentPage, current]);

    const fetchMore = () => {
        REST.getRequests(currentPage, pageSize).then(rr => {
            setCurrentPage(prevPage => prevPage + 1);
            setItems(prevItems => [...prevItems, ...rr]);
        });
    };

    const setAccepted = (r: RequestType) => {
        REST.acceptRequest(r.id);
        setCurrent(r);
    }

    if(me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <AdminMenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={<Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}><CircularProgress
                        color="inherit"/></Box>}
                    endMessage={items.length > 0 ? '' :
                        <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}>
                            <Typography>Обращений нет</Typography></Box>}
                >
                    {items.map((r, index) => (
                        <ExtendedRequestItem key={r.id} item={r} onClick={() => setAccepted(r)}/>
                    ))}
                </InfiniteScroll>
            </Container>

            <AdminRequestModal item={[current, setCurrent]} me={[me, setMe]}/>
        </Grid2>
    );
}