import React, {ReactElement, useEffect, useState} from "react";
import {MenuBlock} from "../component/MenuBlock";
import {Box, CircularProgress, Container, Grid2, Toolbar, Typography} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {RequestType} from "../type/RequestType";
import {REST} from "../REST";
import {RequestItem} from "../component/RequestItem";
import {Normalizer} from "../utils/Normalizer";

export default function RequestsPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const pageSize: number = 10;
    const [items, setItems] = useState<RequestType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        if (currentPage === 0) {
            REST.getRequests(currentPage, pageSize).then(rr => {
                if (rr.length < pageSize) {
                    setHasMore(false);
                }
                setItems(rr);
            });
        }
    }, [currentPage]);

    const fetchMore = () => {
        REST.getRequests(currentPage, pageSize).then(rr => {
            setCurrentPage(prevPage => prevPage + 1);
            setItems(prevItems => [...prevItems, ...rr]);
        });
    };

    const filterPrivate = (item: RequestType): boolean => {
        if (item.type.code === 'appeal_private') {
            return (Normalizer.hasRole('admin') || Normalizer.hasRole('chairman') || item.author.id === me.id);
        }
        return true;
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={<Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}><CircularProgress
                        color="inherit"/></Box>}
                    endMessage={items.filter(item => filterPrivate(item)).length > 0 ? '' :
                        <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}>
                            <Typography>Обращений нет</Typography></Box>}
                >
                    {items.filter(item => filterPrivate(item)).map((item, index) => (
                        <RequestItem key={item.id} item={item}/>
                    ))}
                </InfiniteScroll>
            </Container>

        </Grid2>
    );
}