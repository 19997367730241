import React, {ReactElement, useEffect, useState} from "react";
import {
    Container,
    Grid2,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Toolbar, Typography
} from "@mui/material";
import {MenuBlock} from "../component/MenuBlock";
import {ThemeType} from "../type/ThemeType";
import {Normalizer} from "../utils/Normalizer";
import {REST} from "../REST";
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import {useNavigate} from "react-router-dom";
import ThemeModal from "../modal/ThemeModal";

export default function ForumPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const navigate = useNavigate();

    //const [items, setItems] = useState<MessageType[]>([]);
    const [themes, setThemes] = useState<ThemeType[]>([]);
    const [current, setCurrent] = useState<ThemeType | null>(null);
    const empty: ThemeType = {id: BigInt(0), name: '', author: me, messageCount: BigInt(0), status: 0, lastMessage: null};

    useEffect(() => {
        REST.getForumThemes().then(tt => {
            setThemes(tt);
        });
    }, [current]);

    return (<>
        <Grid2 sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <TableContainer component={Paper} sx={{mt: {xs: 1, sm: 2}}}>
                    <Table sx={{minWidth: 650}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{Normalizer.hasRole("admin") || Normalizer.hasRole("chairman") || Normalizer.hasRole("board_member")
                                    ? <AddCommentIcon sx={{cursor: "pointer"}} color={"primary"} onClick={() => setCurrent(empty)}/>
                                    : ""
                                }</TableCell>
                                <TableCell>Название темы</TableCell>
                                <TableCell align="right">Сообщений</TableCell>
                                <TableCell align="right">Автор</TableCell>
                                <TableCell align="right">Последнее сообщение</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {themes.length > 0
                                ? themes.map((t) => (
                                    <TableRow hover={true} key={t.id}
                                              sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: "pointer"}}
                                              onClick={(event) => {navigate('/forum/' + t.id.toString())}}>
                                        <TableCell component="th" scope="row">{t.status === 1
                                            ? <CommentIcon color={"success"}/>
                                            : <CommentsDisabledIcon color={"error"}/>
                                        }</TableCell>
                                        <TableCell align="left">{t.name}</TableCell>
                                        <TableCell align="right">{t.messageCount.toString()}</TableCell>
                                        <TableCell align="right">{Normalizer.shortFio(t.author.fio)}</TableCell>
                                        <TableCell align="right">
                                            <Typography variant={'caption'}>{t.lastMessage?.publishDate}</Typography>
                                            <Typography
                                                variant={'body2'}>{Normalizer.shortFio(t.lastMessage?.author.fio ?? '')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : <TableRow key={0} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row" align={"center"} colSpan={5}>нет
                                        тем</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>

            <ThemeModal theme={[current, setCurrent]} me={[me, setMe]}/>
        </Grid2>
    </>)
}