import React, {ReactElement, useState} from "react";
import {Avatar, Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {Logout, Settings, Email} from "@mui/icons-material";
import {REST} from "../REST";
import {Normalizer} from "../utils/Normalizer";
import {useNavigate} from "react-router-dom";
import RequestModal from "../modal/RequestModal";

export default function ProfileMenu(props: any): ReactElement {
    const [me, setMe] = props.me;
    const navigate = useNavigate();
    const [showRequestModal, setShowRequestModal] = useState(false);

    const [anchorEl, setAnchorEl] = props.anchor;
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem onClick={() => navigate('/me')} disabled>
                <Avatar src={REST.BASE + '/api/user/' + me.id + '/avatar'}/> {Normalizer.shortFio(me.fio)}
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => setShowRequestModal(true)}>
                <ListItemIcon><Email/></ListItemIcon>Написать обращение</MenuItem>

            {Normalizer.hasRole("admin") || Normalizer.hasRole("chairman")
                ? <MenuItem onClick={() => navigate('/admin')}>
                    <ListItemIcon><Settings/></ListItemIcon>Управление сайтом</MenuItem>
                : ""
            }
            <Divider/>
            <MenuItem onClick={() => {
                REST.logout();
                setMe(JSON.parse('{}'));
            }}>
                <ListItemIcon><Logout/></ListItemIcon>
                Выйти
            </MenuItem>
        </Menu>

        <RequestModal openState={[showRequestModal, setShowRequestModal]} me={[me, setMe]}/>
    </>);
}
