import React, {ReactElement} from "react";
import {AdminMenuBlock} from "../../component/admin/AdminMenuBlock";
import {Container, Grid2, Toolbar} from "@mui/material";
import {Navigate} from "react-router-dom";
import {Normalizer} from "../../utils/Normalizer";

export default function AdminPage(props: any): ReactElement {
    const [me, setMe] = props.me;

    if(me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <AdminMenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>

            </Container>
        </Grid2>
    );
}