import {ChangeEvent, Dispatch, ReactElement, SetStateAction} from "react";
import {Chip, TextField} from "@mui/material";

export default function AppealPrivate(props: any): ReactElement {
    const [filled, setFilled] = props.filled as [boolean, Dispatch<SetStateAction<boolean>>];
    const [content, setContent] = props.content as [any, Dispatch<SetStateAction<any>>];

    const handleOnChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setContent({"content": event.target.value});
        if (event.target.value.length > 0) {
            setFilled(true);
        } else {
            setFilled(false);
        }
    }

    return (<>
        <Chip sx={{mt: 2}} color={'error'} label="Видно только автору" variant="outlined" />
        <TextField sx={{mt: 2}}
                       id={"content"}
                       label={"Содержание"}
                       name={"content"}
                       type={"text"}
                       fullWidth
                       required
                       multiline={true}
                       rows={5}
                       onChange={handleOnChangeContent}
    />
        </>)
}