import React, {ReactElement, useEffect, useState} from "react";
import {Box, CircularProgress, Container, Divider, Grid2, Toolbar, Typography} from "@mui/material";
import {NewsItem} from "../component/NewsItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import {NewsType} from "../type/NewsType";
import {REST} from "../REST";
import {MenuBlock} from "../component/MenuBlock";
import {BannerType} from "../type/BannerType";
import {BannerItem} from "../component/BannerItem";

export default function IndexPage(props: any): ReactElement {
    const [me, setMe] = props.me;

    const pageSize: number = 10;
    const [news, setNews] = useState<NewsType[]>([]);
    const [banners, setBanners] = useState<BannerType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        if (currentPage === 0) {
            REST.getNews(currentPage, pageSize).then(nn => {
                if (nn.length < pageSize) {
                    setHasMore(false);
                }
                setNews(nn);
            });
        }
        REST.getBanners().then(bb => {
            setBanners(bb);
        }).catch(e => {

        });

    }, [currentPage]);

    const fetchMore = () => {
        REST.getNews(currentPage, pageSize).then(nn => {
            setCurrentPage(prevPage => prevPage + 1);
            setNews(prevItems => [...prevItems, ...nn]);
        });
    };

    return (<>
        <Grid2 sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <Grid2 container spacing={1} columns={{xs: 1, sm: 4}}>

                    <Grid2 columns={1} size={1} sx={{mt: 2}}>
                        {banners.map(b => <BannerItem key={b.id} item={b} onClick={() => { }}/>)}
                        <Divider/>
                    </Grid2>

                    <Grid2 sx={{mt: {xs: 0, sm: 1}}} size={{xs: 1, sm: 3}}>
                        <InfiniteScroll
                            dataLength={news.length}
                            next={fetchMore}
                            hasMore={hasMore}
                            loader={<Box
                                sx={{width: '100%', textAlign: 'center', overflow: 'hidden'}}><CircularProgress
                                color="inherit"/></Box>}
                            endMessage={news.length > 0 ? '' :
                                <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden'}}>
                                    <Typography>Новостей и объявлений нет</Typography></Box>}
                        >
                            {news.map((item, index) => (<NewsItem key={item.id} news={item}/>))}
                        </InfiniteScroll>
                    </Grid2>
                </Grid2>

            </Container>

        </Grid2>
    </>)
}
