import {FormEvent, ReactElement, useEffect, useState} from "react";
import {
    Avatar, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {RequestType, RequestTypeType} from "../type/RequestType";
import {REST} from "../REST";
import RepairPipe from "./request/RepairPipe";
import Statement from "./request/Statement";
import AppealPrivate from "./request/AppealPrivate";
import AppealPublic from "./request/AppealPublic";

export default function RequestModal(props: any): ReactElement {
    const [open, setOpen] = props.openState;
    const [me, setMe] = props.me;

    const [requestTypes, setRequestTypes] = useState<RequestTypeType[]>([]);

    const [type, setType] = useState<RequestTypeType | null>(null);
    const [content, setContent] = useState<any>({});

    const [createEnabled, setCreateEnabled] = useState<boolean>(false);

    useEffect(() => {
        REST.getRequestTypes().then(rt => {
            setRequestTypes(rt);
            setType(rt.filter(rtt => rtt.default)[0]!);
        })
    }, [open])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //const formData = new FormData(event.currentTarget);
        //const formJson = Object.fromEntries((formData as any).entries());

        let newRequest: RequestType = {
            id: BigInt(0),
            type: type!,
            content: content,
            author: {id: me.id as bigint, fio: '', login: '', disabled: false, roles: [], plots: []},
            publishDate: '',
            acceptedDate: null,
            completedDate: null,
            expired: null,
            answer: null,
        }
        REST.createRequest(newRequest).then(r => {
            setOpen(false);
        })
    }

    const handleOnChangeType = (event: SelectChangeEvent) => {
        setType(requestTypes.filter(rt => BigInt(rt.id) === BigInt(event.target.value)).at(0)!);
    };

    return (<Dialog
        open={open}
        maxWidth={'lg'}
        onClose={() => setOpen(false)}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit
        }}
    >
        <DialogTitle>
            <Stack direction={"row"} spacing={1} sx={{justifyContent: 'center'}}>
                <Typography component={"h6"} variant={"h6"}
                            sx={{textAlign: "center"}}>Новое обращение</Typography></Stack>
        </DialogTitle>
        <DialogContent>

            <Box sx={{display: 'flex', alignItems: 'flex-end', mt: 1}}>
                <Avatar src={REST.BASE + '/api/user/' + me.id + '/avatar'}
                        sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                <TextField id="author" label="Автор" variant="standard" fullWidth disabled required
                           slotProps={{inputLabel: {shrink: true}}} value={me.fio}/>
            </Box>

            <FormControl fullWidth required sx={{mt: 2}}>
                <InputLabel id="type-label">Тип обращения</InputLabel>
                <Select
                    labelId="type-label"
                    id="type"
                    defaultValue={type?.id.toString()}
                    value={type?.id.toString()}
                    label="Тип обращения"
                    onChange={handleOnChangeType}
                >
                    {requestTypes.map((tt: RequestTypeType, index) =>
                        <MenuItem value={tt.id.toString()} key={tt.id}>{tt.name}</MenuItem>
                    )}
                </Select>
            </FormControl>

            {type?.code === 'repair_pipe' || type?.code === 'clean_street' || type?.code === 'repair_road'
                ? <RepairPipe filled={[createEnabled, setCreateEnabled]} content={[content, setContent]}/>
                : type?.code === 'appeal_private'
                    ? <AppealPrivate filled={[createEnabled, setCreateEnabled]} content={[content, setContent]}/>
                    : type?.code === 'appeal_public'
                        ? <AppealPublic filled={[createEnabled, setCreateEnabled]} content={[content, setContent]}/>
                        : <Statement filled={[createEnabled, setCreateEnabled]} content={[content, setContent]}/>
            }

        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setOpen(false)}>Отмена</Button>
            <Button type="submit" disabled={!createEnabled} variant="contained">Отправить</Button>
        </DialogActions>
    </Dialog>);
}