import React, {Fragment, ReactElement, useEffect, useState} from "react";
import {
    Avatar,
    Container,
    Grid2,
    Divider,
    List,
    ListItemAvatar,
    ListItemText,
    Toolbar,
    Typography, ListItemButton
} from "@mui/material";
import {Normalizer} from "../../utils/Normalizer";
import {Navigate} from "react-router-dom";
import {UserType} from "../../type/UserType";
import {REST} from "../../REST";
import {AdminMenuBlock} from "../../component/admin/AdminMenuBlock";
import UserModal from "../../modal/UserModal";

export default function AdminUserPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const [items, setItems] = useState<UserType[]>([]);
    const [current, setCurrent] = useState<UserType | null>(null);

    useEffect(() => {
        REST.getUsers().then(uu => {
            setItems(uu);
        }).catch(e => {

        });
    }, [current])

    if (me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <AdminMenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <List sx={{bgcolor: 'background.paper', mt: 2}}>

                    {items.map((item, index) =>
                        <Fragment key={item.id}>
                            <ListItemButton alignItems="flex-start" onClick={() => setCurrent(item)} disabled={item.disabled}>
                                <ListItemAvatar>
                                    <Avatar src={REST.BASE + '/api/user/' + item.id + '/avatar'}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.fio}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" sx={{
                                                color: 'text.secondary',
                                                display: 'inline',
                                                mr: 1
                                            }}>{'+7' + Normalizer.formatPhone(item.login)}</Typography>
                                            <Typography component="span" variant="body2" sx={{
                                                color: 'text.primary',
                                                display: 'inline'
                                            }}>{item.roles?.sort((a,b) => a.type - b.type).map(r => r.name).join(', ')}</Typography>
                                        </>
                                    }
                                />
                            </ListItemButton>
                            {index < items.length - 1 ? <Divider variant="inset" component="li"/> : ""}
                        </Fragment>
                    )}
                </List>
            </Container>

            <UserModal user={[current, setCurrent]} me={[me, setMe]}/>
        </Grid2>
    )
}