import {ReactElement} from "react";
import {Avatar, ListItemButton, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {DocumentType} from "../type/DocumentType";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export function DocumentItem(props: any): ReactElement {
    const item: DocumentType = props.item as DocumentType;
    const onClick = props.onClick;

    return (
        <ListItemButton alignItems="flex-start" onClick={onClick}>
            <ListItemAvatar>
                <Avatar><FileDownloadIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={item.name}
                secondary={
                    <>
                        <Typography component="span" variant="body2" sx={{ color: 'text.secondary', display: 'inline', mr: 1 }}>{item.uploadedAt}</Typography>
                    </>
                }
            />
        </ListItemButton>
    )
}

export function SimpleDocumentItem(props: any): ReactElement {
    const item: DocumentType = props.item as DocumentType;
    const onClick = props.onClick;

    return (
        <ListItemButton alignItems="flex-start" onClick={onClick}>
            <ListItemText
                primary={item.name}
                secondary={
                    <>
                        <Typography component="span" variant="body2" sx={{ color: 'text.secondary', display: 'inline', mr: 1 }}>{item.uploadedAt}</Typography>
                        <Typography component="span" variant="body2" sx={{ color: 'text.primary', display: 'inline' }}>{item.section.name}</Typography>
                    </>
                }
            />
        </ListItemButton>
    )
}