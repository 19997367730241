import {ReactElement, useEffect, useState} from "react";
import {Normalizer} from "../../utils/Normalizer";
import {Navigate} from "react-router-dom";
import {Card, CardActionArea, CardContent, Container, Grid2, Toolbar} from "@mui/material";
import {AdminMenuBlock} from "../../component/admin/AdminMenuBlock";
import {REST} from "../../REST";
import AddIcon from '@mui/icons-material/Add';
import {NewsType} from "../../type/NewsType";
import {SimpleNewsItem} from "../../component/NewsItem";
import NewsModal from "../../modal/NewsModal";

export default function AdminNewsPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const [news, setNews] = useState<NewsType[]>([]);
    const [current, setCurrent] = useState<NewsType | null>(null);
    const empty: NewsType = {
        id: BigInt(0),
        title: '',
        content: '',
        author: me,
        publishDate: '',
        liked: false,
        countLike: 0
    };

    useEffect(() => {
        REST.getNews(0, 10).then(nn => {
            setNews(nn);
        }).catch(e => {

        });
    }, [current])

    if(me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <AdminMenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <Grid2 container spacing={1} columns={{ xs: 1, sm: 4 }} sx={{mt: 2}}>

                    <Grid2 size={{ xs: 1, sm: 2 }} key={0}>
                        <Card sx={{minWidth: 150}} variant="outlined">
                            <CardActionArea onClick={() => setCurrent(empty)}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <AddIcon/>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid2>

                    <Grid2 size={{ xs: 1, sm: 2 }}>
                    {news.map(n => <SimpleNewsItem key={n.id} news={n} onClick={() => setCurrent(n)}/>)}
                    </Grid2>
                </Grid2>
            </Container>

            <NewsModal news={[current, setCurrent]} me={[me, setMe]}/>
        </Grid2>
    );
}