import React, {ChangeEvent, FormEvent, ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Normalizer} from "../utils/Normalizer";
import {REST} from "../REST";
import {PhoneInput} from "../component/PhoneInput";

export default function LoginModal(props: any): ReactElement {
    const [open, setOpen] = props.openState;
    const [me, setMe] = props.me;

    const [loginDisabled, setLoginDisabled] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [currentPhone, setCurrentPhone] = useState<string | null>(null);
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);

    const handleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        setError(null);
        if (/^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(event.target.value ?? ''))) {
            setCurrentPhone(Normalizer.phone(event.target.value ?? ''));
        }
        if (/^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(event.target.value ?? ''))
            && (currentPassword?.length ?? 0) > 0) {
            setLoginDisabled(false);
        } else {
            setLoginDisabled(true);
        }
    }

    const handleOnChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setError(null);
        setCurrentPassword(event.target.value);
        if (event.target.value.length > 0
            && /^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(currentPhone ?? ''))) {
            setLoginDisabled(false);
        } else {
            setLoginDisabled(true);
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        const phone = Normalizer.phone(formJson.phone);
        const password = formJson.password;
        REST.login(phone, password).then(j => {
            setMe(JSON.parse(sessionStorage.getItem("me") ?? '{}'));
            setOpen(false);
        }).catch((e) => {
            setError(e.message);
            setLoginDisabled(true);
        });
    };

    return (<Dialog
        open={open}
        onClose={() => {
            setError(null);
            setOpen(false);
        }}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit
        }}
    >
        <DialogTitle>
            <Stack direction={"row"} spacing={1} sx={{justifyContent: 'center'}}>
                <img src={"/tree.png"} height={"28px"} alt={""}/>
                <Typography component={"h5"} variant={"h5"}
                            sx={{textAlign: "center"}}>{process.env.REACT_APP_TITLE}</Typography></Stack>
        </DialogTitle>
        <DialogContent>

            <TextField id={"phone"} name={"phone"} label={"Телефон"} fullWidth required
                       slotProps={{
                           input: {
                               startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                               inputComponent: PhoneInput as any
                           },
                           inputLabel: {shrink: true},
                       }}
                       onChange={handleOnChangePhone}
                       onInput={handleOnChangePhone}
                       sx={{mt: 1}}
            />

            <TextField sx={{mt: 2}}
                       id={"password"}
                       label={"Пароль"}
                       name={"password"}
                       type={"password"}
                       autoComplete={"current-password"}
                       onChange={handleOnChangePassword}
                       onInput={handleOnChangePassword}
                       fullWidth
                       required
                       slotProps={{inputLabel: {shrink: true}}}
            />
            {error
                ? <Typography variant={'body2'} color={'error'} sx={{mt: 1, textAlign: 'center'}}>{error}</Typography>
                : ""
            }
        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setOpen(false)}>Отмена</Button>
            <Button type="button" variant="contained" color={"success"} sx={{display: {xs: 'none', sm: 'flex'}}}
                    disabled>Зарегистрироваться</Button>
            <Button type="submit" disabled={loginDisabled} variant="contained">Войти</Button>
        </DialogActions>
    </Dialog>);
}