import {ReactElement, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import IndexPage from "./page/IndexPage";
import DocumentPage from "./page/DocumentPage";
import {UserType} from "./type/UserType";
import MePage from "./page/MePage";
import NewsPage from "./page/NewsPage";
import AdminPage from "./page/admin/AdminPage";
import AdminBannerPage from "./page/admin/AdminBannerPage";
import AdminNewsPage from "./page/admin/AdminNewsPage";
import AdminDocumentPage from "./page/admin/AdminDocumentPage";
import AdminRequestPage from "./page/admin/AdminRequestPage";
import AdminUserPage from "./page/admin/AdminUserPage";
import RequestsPage from "./page/RequestsPage";
import ForumPage from "./page/ForumPage";
import AdminMailingPage from "./page/admin/AdminMailingPage";
import ThemePage from "./page/ThemePage";

export function App(): ReactElement {
    const [me, setMe] = useState<UserType>(JSON.parse(sessionStorage.getItem("me") ?? '{}'));

    return (<>
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<IndexPage me={[me, setMe]}/>}/>
                <Route path={"/news"} element={<NewsPage me={[me, setMe]}/>}/>
                <Route path={"/docs"} element={<DocumentPage me={[me, setMe]}/>}/>
                <Route path={"/requests"} element={<RequestsPage me={[me, setMe]}/>}/>
                <Route path={"/forum"} element={<ForumPage me={[me, setMe]}/>}/>
                <Route path={"/forum/:tid/"} element={<ThemePage me={[me, setMe]}/>}/>

                <Route path={"/me"} element={<MePage me={[me, setMe]}/>}/>

                <Route path={"/admin"} element={<AdminPage me={[me, setMe]}/>}/>
                <Route path={"/admin/news"} element={<AdminNewsPage me={[me, setMe]}/>}/>
                <Route path={"/admin/docs"} element={<AdminDocumentPage me={[me, setMe]}/>}/>
                <Route path={"/admin/requests"} element={<AdminRequestPage me={[me, setMe]}/>}/>
                <Route path={"/admin/banners"} element={<AdminBannerPage me={[me, setMe]}/>}/>
                <Route path={"/admin/users"} element={<AdminUserPage me={[me, setMe]}/>}/>
                <Route path={"/admin/mailing"} element={<AdminMailingPage me={[me, setMe]}/>}/>

                <Route path={"*"} element={<Navigate to={"/"}/>}/>
            </Routes>
        </BrowserRouter>
    </>);
}
