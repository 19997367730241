import {ChangeEvent, FormEvent, ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from "@mui/material";
import {REST} from "../REST";
import {HTMLInput} from "../component/HTMLInput";
import {NewsType} from "../type/NewsType";

export default function NewsModal(props: any): ReactElement {
    const [news, setNews] = props.news;
    const [me, setMe] = props.me;

    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [currentTitle, setCurrentTitle] = useState<string>('');
    const [currentContent, setCurrentContent] = useState<string>('');

    const handleOnChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentTitle(event.target.value);
        if (event.target.value.length > 0 && currentContent.length > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const handleOnChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentContent(event.target.value);
        if (event.target.value.length > 0 && currentTitle.length > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //const formData = new FormData(event.currentTarget);
        //const formJson = Object.fromEntries((formData as any).entries());

        //const title = formJson.title;
        const newNews: NewsType = {
            id: news.id,
            author: me,
            publishDate: '',
            title: currentTitle,
            content: currentContent,
            liked: false,
            countLike: 0
        }
        REST.saveNews(newNews).then(n => {
            setNews(null);
        });
    };

    const removeNews = () => {
        REST.removeNews(news.id).then(() => {
            setNews(null);
        });
    }

    return (<Dialog sx={{zIndex: 2}}
                    fullWidth
                    maxWidth={'lg'}
                    open={news !== null}
                    onClose={() => setNews(null)}
                    PaperProps={{
                        component: 'form',
                        onSubmit: handleSubmit
                    }}
    >
        <DialogContent>
            <TextField sx={{mt: 1}}
                       id={"title"}
                       label={"Заголовок"}
                       name={"title"}
                       type={"text"}
                       fullWidth
                       required
                       slotProps={{inputLabel: {shrink: true}}}
                       onChange={handleOnChangeTitle}
                       defaultValue={news?.title}
            />

            <TextField sx={{mt: 2}}
                       id={"text"}
                       label={"Содержание"}
                       name={"text"}
                       fullWidth
                       required
                       multiline
                       rows={15}
                       slotProps={{
                           input: {
                               inputComponent: HTMLInput as any
                           },
                           inputLabel: {shrink: true},
                       }}
                       onChange={handleOnChangeContent}
                       defaultValue={news?.content}
            />

        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setNews(null)}>Отмена</Button>
            {news?.id === BigInt(0)
                ? ''
                : <Button color={"error"} variant={"contained"} onClick={() => removeNews()}>Удалить</Button>
            }
            <Button color={'success'} type="submit" disabled={saveDisabled} variant={"contained"}>Сохранить</Button>
        </DialogActions>
    </Dialog>);
}